import React, { FC, useCallback } from 'react';
import { graphql } from 'gatsby';

import IconCustom from 'common/IconCustom';

import { IPropsStartQuizSection } from './models';
import './StartQuizSection.scss';
import './ShadowStartQuizSection.scss';

const StartQuizSection: FC<IPropsStartQuizSection> = ({
  startQuiz: [
    {
      properties: { boldTitle, lightTitle, ariaLabel },
    },
  ],
  handleQuizVisibility,
}) => {
  const handleOpen = useCallback(() => {
    handleQuizVisibility(true);
  }, []);

  return ariaLabel && lightTitle ? (
    <button
      className="start-quiz-section"
      data-test="StartQuizSection"
      type="button"
      aria-label={ariaLabel}
      onClick={handleOpen}
    >
      <span className="start-quiz-block">
        <IconCustom icon="product_finder" className="start-quiz-section__icon" />
        <span className="start-quiz-section__span">
          <strong>{boldTitle}</strong>
          <span>{lightTitle}</span>
        </span>
      </span>
      <span className="start-quiz-section__button">
        <IconCustom icon="arrow3-up" className="back-to-top" />
      </span>
    </button>
  ) : null;
};

export const query = graphql`
  fragment FragmentStartQuizSection on TStartQuiz {
    properties {
      boldTitle
      lightTitle
      ariaLabel
    }
  }
`;

export default StartQuizSection;
